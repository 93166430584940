import { DirectiveOptions } from "vue";

const viewportresize: DirectiveOptions = {
    inserted(el, binding) {
        const onResizeCallback = binding.value;
        window.addEventListener('resize', () => {
            const width = document.documentElement.clientWidth;
            const height = document.documentElement.clientHeight;
            onResizeCallback({ width, height });
        });
    }
};

export default viewportresize;